














































import ContentCard from "@/components/ContentCard.vue";
import Table from "@/components/Table.vue";
import useNavigateToApplication from "@/composables/navigation/useNavigateToApplication";
import useProfile from "@/composables/useProfile";
import { useGetApplicationsQuery } from "@/graphql/types";
import { TableField } from "@/types/TableField";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, provide } from "@vue/composition-api";
import { useNumberFormat } from "@/composables/useNumberFormat";
import { ParentQueryKey } from "./symbols";

export default defineComponent({
  components: { ContentCard, Table },
  setup(_, { root }) {
    const { isCentralAdmin, isTechnicalAdmin } = useProfile();

    const { result, loading, error, document, variables } =
      useGetApplicationsQuery();
    provide(ParentQueryKey, {
      query: document.value,
      variables: variables.value,
    });

    return {
      editable: computed(() => isCentralAdmin.value || isTechnicalAdmin.value),
      loading,
      error,
      useNavigateToApplication,
      items: useResult(result, [], (data) => data.applications.nodes),
      totalCount: useResult(
        result,
        undefined,
        (data) => data.applications.totalCount
      ),
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          filter: true,
          sortable: true,
          label: root.$tc("applications.fields.name"),
        },
        {
          key: "ownerOrganization.name",
          filter: true,
          sortable: true,
          label: root.$tc("applications.fields.owner"),
        },
        {
          key: "roles",
          filter: false,
          sortable: true,
          label: root.$tc("applications.fields.role_count"),
          width: "10%",
        },
      ]),
      useNumberFormat,
    };
  },
});
